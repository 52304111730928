<template>
    <div class="home-foot">
        <div class="copyright flex align-items">
            <p>版权所有 安徽爱沃客信息科技有限公司 ©CopyRight 2020-2022   <a href="https://beian.miit.gov.cn/" target="_blank">皖ICP备18007608号-1</a></p>
            <p><a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=34019102000300" target="_blank"><img src="../../assets/img/police.png"/>皖公网安备 34019102000300号</a></p>
        </div>
        <a-popover placement="leftTop">
            <div class="wechat"><i class="iconfont icon-wx"></i></div>
            <template #content>
            <img src="../../assets/img/code.png" alt="" class="code-img">
            </template>
        </a-popover>
        
    </div>
</template>
<style lang="less" scoped>
@import url(../../assets/css/init/var.less);
.home-foot{
    padding: 0 40px 33px;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
}
.copyright{
    p{
        margin-right: 24px;
        font-size: 12px;
        color: @font-color999;
        a{
            font-size: 12px;
            color: @font-color999;
        }
        img{
            width: 18px;
            height: 18px;
            margin-right: 4px;
        }
    }
}
.wechat{
    width: 48px;
    height: 48px;
    background-color: @main-color;
    border-radius: 50%;
    position: fixed;
    right: 36px;
    bottom: 30px;
    text-align: center;
    line-height: 48px;
    i{
        color: @white;
        font-size: @init-font+20px;
    }
}
.white-foot{
    .copyright{
        p,a{
            color: rgba(255,255,255,.7);
        }
    }
    .wechat{
        background-color: rgba(255,255,255,.2);
    }
}
.code-img{
    display: block;
    width: 170px;
}
</style>