<template>
    <div class="web-pagination">
        <a-pagination
            v-model:current="current"
            :page-size="pageSize"
            :total="total"
            show-less-items
            @change="change"
        />
    </div>
</template>
<script>
import {defineComponent} from 'vue';

export default defineComponent({
    props: {
        page: {
            type: [Number, String],
            default: 1,
        },
        pagesize: {
            type: [Number, String],
            default: 10,
        },
        count: {
            type: [Number, String],
            default: '0',
        },
    },
    watch: {
        count(val) {
            this.total = parseInt(val);
        }
    },
    data() {
        return {
            current: parseInt(this.page),
            pageSize: parseInt(this.pagesize),
            total: parseInt(this.count),
        };
    },
    methods: {
        change(e) {
            this.$emit('pageTo', e);
        }
    }
})
</script>
<style lang="less" scoped>
@import url(../../assets/css/init/var.less);

.web-pagination {
    padding-top: 33px;
    display: flex;
    justify-content: center;

    /deep/ .ant-pagination-item {
        height: 38px;
        line-height: 38px;
        width: 40px;
        border-radius: 0;

        a {
            color: @font-color4d;
        }
    }

    /deep/ .ant-pagination-prev {
        width: 40px;
        height: 38px;
    }

    /deep/ .ant-pagination-next {
        width: 40px;
        height: 38px;
    }

    /deep/ .ant-pagination-item-active {
        background-color: @main-color;
        color: @white;

        a {
            color: @white;
        }
    }

    /deep/ .ant-pagination-item:hover {
        background-color: @main-color;

        a {
            color: @white;
        }
    }
}
</style>