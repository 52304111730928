<template>
    <div class="wrap">
        <top-header
            :scenario="'about'"
        />

        <div class="web-banner">
            <img src="../../assets/img/about_us_banner.png" alt="">
            <div class="banner-word flex align-items">
                <h3>爱沃客</h3>
                <h4>IWOWKE</h4>
            </div>
        </div>

        <web-tabs
            :select-key="3"
        />

        <div class="about-column">
            <div class="content-wrap">
                <div class="company-culture-news">
                    <div class="news-column flex">
                        <div class="news" v-for="(item, key) in data" :key="key">
                            <a :href="'/news/' + item.newsid" target="_blank">
                                <img :src="item.imageUrl" :alt="item.title">
                                <h3 class="word-hidden">{{ item.title }}</h3>
                            </a>
                        </div>
                    </div>

                    <web-pagination
                        :page="postData.page"
                        :pagesize="postData.pagesize"
                        :count="count"
                        @pageTo="pageTo"
                    />
                </div>
            </div>
        </div>

        <friend-link/>
        <bottom-foot/>
    </div>
</template>
<script>
import topHeader from '../../components/common/topHeader.vue';
import webTabs from '../../components/common/webTabs.vue';
import friendLink from '../../components/common/friendLink.vue';
import bottomFoot from '../../components/common/bottomFoot.vue';
import webPagination from '../../components/common/webPagination.vue';


export default {
    components: {
        topHeader,
        webTabs,
        friendLink,
        bottomFoot,
        webPagination,
    },
    data () {
        return {
            scenario: 'aboutWenhua',
            postData: {
                flag: 'wenhua',
                page: 1,
                pagesize: 9,
            },
            data: [],
            count: '0',
        };
    },
    created() {
        this.getData();
    },
    methods: {
        getData() {
            this.http('/www/home/news-list', this.postData, (res) => {
                this.data = res.list.data;
                this.count = res.list.count;
            }, null, 2);
        }
    }
}
</script>
<style lang="less" src="../../assets/css/init/common.less" scoped></style>
<style lang="less" src="../../assets/css/about/companyProfile.less" scoped></style>