<template>
    <div class="wrap">
        <top-header
            :scenario="'about'"
        />

        <div class="web-banner">
            <img src="../../assets/img/about_us_banner.png" alt="">
            <div class="banner-word flex align-items">
                <h3>爱沃客</h3>
                <h4>IWOWKE</h4>
            </div>
        </div>

        <web-tabs
            :select-key="2"
        />

        <div class="about-column">
            <div class="content-wrap">
                <div class="honor-swiper">
                    <swiper
                        :modules="modules"
                        :slides-per-view="3"
                        :slidesPerGroup="3"
                        :space-between="76"
                        :pagination="{ clickable: true }"
                        :grid="{fill: 'row',rows: 2}"
                        @swiper="onSwiper"
                        @slideChange="onSlideChange"
                    >
                        <swiper-slide v-for="(item, key) in data" :key="key">
                            <div class="honor-display">
                                <h3>{{ item.qname }}</h3>
                                <div class="img"><img :src="item.imageUrl" :alt="item.qname"></div>
                            </div>
                        </swiper-slide>
                    </swiper>
                </div>
            </div>
        </div>

        <friend-link/>
        <bottom-foot/>
    </div>
</template>
<script>
import topHeader from '../../components/common/topHeader.vue';
import webTabs from '../../components/common/webTabs.vue';
import friendLink from '../../components/common/friendLink.vue';
import bottomFoot from '../../components/common/bottomFoot.vue';

import {Swiper, SwiperSlide} from 'swiper/vue';
import {Pagination, Grid,} from 'swiper';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/grid';

export default {
    components: {
        topHeader,
        webTabs,
        friendLink,
        bottomFoot,
        Swiper,
        SwiperSlide,
    },
    setup() {
        const onSwiper = (swiper) => {
            // console.log(swiper);
        };
        const onSlideChange = () => {
            // console.log('slide change');
        };
        return {
            onSwiper,
            onSlideChange,
            modules: [Pagination, Grid],
        };
    },
    data () {
        return {
            scenario: 'aboutHonor',
            postData: {
                flag: 'about_honor'
            },
            data: [],
        };
    },
    created() {
        this.getData();
    },
    methods: {
        getData() {
            this.http('/www/home/qiyead', this.postData, (res) => {
                this.data = res.list.data;
            }, null, 2);
        }
    }
}
</script>
<style lang="less" src="../../assets/css/init/common.less" scoped>

</style>
<style lang="less" src="../../assets/css/about/companyProfile.less" scoped>

</style>