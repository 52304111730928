<template>
    <div class="wrap">
        <top-header
            :scenario="'about'"
        />

        <div class="web-banner">
            <img src="../../assets/img/about_us_banner.png" alt="">
            <div class="banner-word flex align-items">
                <h3>爱沃客</h3>
                <h4>IWOWKE</h4>
            </div>
        </div>

        <web-tabs
            :select-key="1"
        />

        <div class="content-column">
            <div class="content-wrap">
                <div class="about-us flex">
                    <div class="about-info">
                        <h1>关于我们</h1>
                        <p>安徽爱沃客信息科技有限公司是以⼈⼒资源服务、职业教育服务、继续教育服务为主的科技型⼈⼒资源及⼈才发展服务公司。</p>
                        <p>公司凭借专业化的服务团队以及企业⼈才服务经验优势，以资源和价值共享为核⼼，以专业、科技、互联不断创新⼈才服务模式，为企业提供多元化的⼈⼒资源解决⽅案。</p>
                        <p>公司以协同育⼈推进产教深度融合，创新校企合作内容，逐步形成从招⽣、培养、就业⼀体化职业教育⼈才培养体系，促进职业教育和产业⼈才需求精准对接，构建产教融合新⽣态，夯实⼈才培养基础，打造特⾊校企合作模式。</p>
                        <p>爱沃客信息科技以“让⼯作更简单”为使命，以创新⾏业服务模式为核⼼竞争⼒，构建应⽤型⼈才培养及就业全⽣态。</p>
                    </div>
                    <div class="about-img">
                        <img src="../../assets/img/about_intro_right.png" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="our-column">
            <div class="content-wrap">
                <div class="our-column-title flex">
                    <div class="img"><img src="../../assets/img/about_our_idea.png" alt=""></div>
                    <div class="title flex">
                        <h3>我们的理念</h3>
                        <p>服务企业、服务学校、成就人才</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="our-column sec-grey">
            <div class="content-wrap">
                <div class="our-column-title flex">
                    <div class="title flex">
                        <h3>我们的使命</h3>
                        <p>让工作更简单</p>
                    </div>
                    <div class="img"><img src="../../assets/img/about_our_mission.png" alt=""></div>
                    
                </div>
            </div>
            <div class="grey-bg"></div>
        </div>
        <div class="our-column">
            <div class="content-wrap">
                <div class="our-column-title flex">
                    <div class="img"><img src="../../assets/img/about_our_vision.png" alt=""></div>
                    <div class="title flex">
                        <h3>我们的愿景</h3>
                        <p>构建应⽤型⼈才培养及就业全⽣态</p>
                    </div>
                </div>
            </div>
        </div>

        <friend-link/>

        <bottom-foot/>
    </div>
</template>

<script>
import topHeader from '../../components/common/topHeader.vue';
import webTabs from '../../components/common/webTabs.vue';
import friendLink from '../../components/common/friendLink.vue';
import bottomFoot from '../../components/common/bottomFoot.vue';

export default {
    components: {
        topHeader,
        webTabs,
        friendLink,
        bottomFoot,
    },
    data () {
        return {
            scenario: 'aboutIntro',
        };
    },
}
</script>
<style lang="less" src="../../assets/css/init/common.less" scoped></style>
<style lang="less" src="../../assets/css/about/companyProfile.less" scoped></style>