import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';


const app = createApp(App);

// mixin
import Mixin from './mixin';
app.mixin(Mixin);


import {Button,Dropdown,Menu,Tabs,Icon,Pagination,Form,Input,Anchor,Popover,Carousel} from 'ant-design-vue';
app.use(Button).use(Dropdown).use(Menu).use(Tabs).use(Icon).use(Pagination).use(Form).use(Input).use(Anchor).use(Popover).use(Carousel);

app.use(store).use(router).mount('#app');
