<template>
    <div class="contact-us-column flex">
        <div class="contact-type">
            <i class="iconfont icon-contact1 right-icon"></i> 
            <span class="circle-icon flex"><i class="iconfont icon-contact1"></i></span>   
            <h3>人力资源服务</h3>
            <p class="flex align-items"><i class="iconfont icon-phone"></i><a :href="'tel:'+'15112345698'">15956962129</a><span>汪经理</span></p>
            <p class="flex align-items"><i class="iconfont icon-mail"></i><a href="">wlong@iwowke.com</a></p>
        </div>
        <div class="contact-type">
            <i class="iconfont icon-contact2 right-icon"></i> 
            <span class="circle-icon flex"><i class="iconfont icon-contact2"></i></span>   
            <h3>职业教育服务</h3>
            <p class="flex align-items"><i class="iconfont icon-phone"></i><a :href="'tel:'+'15375341343'">15375341343</a><span>武经理</span></p>
            <p class="flex align-items"><i class="iconfont icon-mail"></i><a href="">wjf@iwowke.com</a></p>
        </div>
        <div class="contact-type">
            <i class="iconfont icon-contact3 right-icon"></i> 
            <span class="circle-icon flex"><i class="iconfont icon-contact3"></i></span>   
            <h3>继续教育服务</h3>
            <p class="flex align-items"><i class="iconfont icon-phone"></i><a :href="'tel:'+'15375341343'">15375341343</a><span>武经理</span></p>
            <p class="flex align-items"><i class="iconfont icon-mail"></i><a href="">wjf@iwowke.com</a></p>
        </div>
        <div class="contact-type">
            <i class="iconfont icon-contact4 right-icon"></i> 
            <span class="circle-icon flex"><i class="iconfont icon-contact4"></i></span>   
            <h3>人力资源蓄水池</h3>
            <p class="flex align-items"><i class="iconfont icon-phone"></i><a :href="'tel:'+'15375341343'">15375341343</a><span>武经理</span></p>
            <p class="flex align-items"><i class="iconfont icon-mail"></i><a href="">wjf@iwowke.com</a></p>
        </div>
    </div>
</template>
<style lang="less" scoped>
@import url(../../assets/css/init/var.less);
.contact-us-column{
    padding-top: 65px;
    .contact-type{
        width: 287px;
        height: 258px;
        border-radius: 8px;
        border: 1px solid @border-color;
        padding-left: 32px;
        padding-top: 32px;
        position: relative;
        margin-right: 31px;
        background-color: @white;
        &:last-child{
            margin-right: 0;
        }
        .right-icon{
            font-size: 110px;
            position: absolute;
            top: 20px;
            right: 10px;
            color: @bg-colorfa;
        }
        .circle-icon{
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background-color: @main-color;
            align-items: center;
            justify-content: center;
            border: 1px solid @main-color;
            transition: all 0.1s ease-in;
            i{
                color: @white;
                font-size: 26px;
            }
        }
        h3{
            font-size: @init-font+2px;
            color: @font-color333;
            font-weight: 600;
            margin-top: 22px;
            padding-bottom: 10px;
        }
        p{
            color: @font-color333;
            margin-top: 23px;
            
            i{
                font-size: 18px;
            }
            a,span{
                color: @font-color333;
                font-size: 14px;
                margin-left: 13px;
            }
        }
    }
    .contact-type:hover{
        transition: background-color 0.5s ease-in;
        background-color: @main-color;
        .circle-icon{
            border-color: @white;
        }
        .right-icon{
            opacity: .1;
        }
        h3,p,a,span{
            color: @white;
        }
    }
}
</style>