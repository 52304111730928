<template>
    <div class="wrap">
        <top-header
            :scenario="scenario"
        />

        <div class="web-banner">
            <img src="../../assets/img/news_banner.png" alt="">
            <div class="banner-word flex align-items">
                <h3>新闻动态</h3>
                <h4>NEWS</h4>
            </div>
        </div>

        <div class="news-list-nav">
            <div class="content-wrap">
                <div class="news-list-column flex">

                    <div class="nav-left">
                        <news-tabs
                            :config="config"
                            :selectKey="flag"
                        />
                    </div>

                    <div class="news-right-list">
                        <div class="news-list" v-for="(item, key) in data" :key="key">
                            <a :href="'/news/' + item.newsid" class="flex" target="_blank">
                                <img :src="item.imageUrl" :alt="item.title">
                                <div class="right-dec">
                                    <h3 class="word-hidden">{{ item.title }}</h3>
                                    <p class="row2">{{ item.description }}</p>
                                    <div class="see-time flex"><span>{{ item.viewnum }}浏览</span><span>{{ item.addtime_str }}</span></div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>

                <div>
                    <web-pagination
                        :page="postData.page"
                        :pagesize="postData.pagesize"
                        :count="count"
                        @pageTo="pageTo"
                    />
                </div>
            </div>
        </div>
        

        <friend-link/>

        <bottom-foot/>
    </div>
</template>
<script>
import topHeader from '../../components/common/topHeader.vue';
import newsTabs from '../../components/common/newsTabs.vue';
import friendLink from '../../components/common/friendLink.vue';
import bottomFoot from '../../components/common/bottomFoot.vue';
import webPagination from '../../components/common/webPagination.vue';

export default {
    components: {
        topHeader,
        newsTabs,
        friendLink,
        bottomFoot,
        webPagination,
    },
    data () {
        return {
            scenario: 'news',
            postData: {
                flag: '',
                page: 1,
                pagesize: 10,
            },
            config: {},
            data: [],
            flag: '',
            count: '0',
        };
    },
    created() {
        this.setQuery();

        this.flag = this.postData.flag;

        this.getData();
    },
    methods: {
        getData() {
            this.http('/www/home/news-list', this.postData, (res) => {
                this.data = res.list.data;
                this.config = res.config;
                this.count = res.list.count;
            }, null, 2);
        }
    }
}
</script>
<style lang="less" src="../../assets/css/init/common.less" scoped>

</style>
<style lang="less" src="../../assets/css/news/news.less" scoped>

</style>