<template>
    <div class="wrap">
        <top-header
            :scenario="'service'"
        />

        <div class="web-banner">
            <img src="../../assets/img/hr_xusc_banner.png" alt="">
            <div class="banner-word flex align-items">
                <h3>人力资源蓄水池</h3>
                <h4>安徽省首个政府主导，爱沃客公司运营的专项精工技能人才培养项目</h4>
                <a-anchor wrapperClass="contact-anchor" :affix="false">
                    <a-anchor-link href="#contact-xsc" title="联系我们"/>
                </a-anchor>
            </div>
        </div>
        <div class="xsc-sub-web-column">
            <div class="content-wrap service-intro-text">
                <p>安徽省首个政府主导的外引水、内调剂人力资源服务创新项目。</p>
                <p>以政府牵头搭建“政、企、校”三方合作，利用精细化合作模式与学校建立长期有效的人才储备机制，以定向人才培养实施人力资源“外引水”，以“引企入教”促进教育链、人才链与产业链、
                    创新链有机衔接，以专业共建和产业学院共建模式，通过定岗、双向、精准培养精益制造的工匠型人才，以精工带动普工形成人力资源 “蓄水池”，为企业提供人力资源支撑保障。</p>
                <p>2020年，受新冠疫情影响，合肥经济技术开发区“蓄水池”创新推出“共享用工”新模式，以共享用工实施人力资源“内调剂”，通过人员流转实现用工余缺调剂，促进人力资源合理调配。</p>

            </div>
        </div>
        <!-- 核心业务 -->
        <div class="core-web-column">
            <div class="content-wrap">
                <div class="column-title">
                    <h1>核心业务</h1>
                </div>
            </div>
        </div>
        <div class="sub-web-column grey-bg">
            <div class="jing-swiper-content">
                <div class="jing-gong-ban flex">
                    <div class="left-text">
                        <h3>精工培养</h3>
                        <p>在提升学生文化课和专业课的同时，定制化培训企业文化、社会实践和职业规划等课程，帮助学生毕业后直接到企业高质量技能岗工作。</p>
                    </div>

                    <div class="right-swiper">
                        <swiper
                            :slides-per-view="3.3"
                            :space-between="24"
                            :navigation="true"
                            :modules="modules"
                            @swiper="onSwiper"
                            @slideChange="onSlideChange"
                        >
                            <swiper-slide v-for="(item, key) in dataNewsJinggong" :key="key">
                                <a :href="'/news/' + item.newsid" target="_blank">
                                    <img :src="item.imageMax" :alt="item.title">
                                    <p>{{ item.title }}</p>
                                </a>
                            </swiper-slide>

                        </swiper>
                    </div>
                </div>
                <div class="jing-gb-data flex">
                    <div class="data">
                        <h2>20<span class="word">家</span></h2>
                        <p>入池企业</p>
                    </div>
                    <div class="data">
                        <h2>88<span>+</span></h2>
                        <p>精工班</p>
                    </div>
                    <div class="data">
                        <h2>3500<span>+</span></h2>
                        <p>精工人才</p>
                    </div>
                    <div class="data">
                        <h2>10000<span>+</span></h2>
                        <p>输送普工</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- 共享用工 -->
        <div class="sub-web-column">
            <div class="content-wrap">
                <div class="share-staff-column flex align-items">
                    <div class="left-images-news flex">
                        <div class="news" v-for="(item, key) in dataNewsShare" :key="key">
                            <a :href="'/news/' + item.newsid" target="_blank">
                                <img :src="item.imageMax" :alt="item.title">
                                <p class="word-hidden">{{ item.title }}</p>
                            </a>
                        </div>
                    </div>
                    <div class="right-intro">
                        <h3>共享用工</h3>
                        <p>
                            针对不同企业的淡旺季，⽤⼈企业间签订共享协议，开展⽤⼯余缺调剂。共享⽤⼯模式取得较好的⼈⼒资源调剂效果。为常态化推进调节⼯作，促进⼈⼒资源合理调配，稳定劳动者就业岗位，合肥经济技术开发区决定成⽴“共享员⼯联盟”，这是合肥市⾸个共享员⼯联盟。</p>
                        <p>2020年8⽉26⽇，合肥⾸个共享员⼯联盟在位于合肥经开区的中国合肥⼈⼒资源服务产业园正式成⽴。</p>
                        <div class="union-purpose">
                            <h3>联盟宗旨</h3>
                            <div class="purpose-icons flex">
                                <div class="icon">
                                    <span class="p-icon1"></span>
                                    <p>企业互助</p>
                                </div>
                                <div class="icon">
                                    <span class="p-icon2"></span>
                                    <p>共享共建</p>
                                </div>
                                <div class="icon">
                                    <span class="p-icon3"></span>
                                    <p>合作共赢</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="jing-gb-data share-data flex align-items">
                    <div class="under-bg"></div>
                    <div class="data">
                        <h2>29<span class="word">家</span></h2>
                        <p>入盟企业</p>
                    </div>
                    <div class="data">
                        <h2>37<span class="word">批次</span></h2>
                        <p>达成合作</p>
                    </div>
                    <div class="data">
                        <h2>3434<span class="word">人</span></h2>
                        <p>共享用工</p>
                    </div>
                    <div class="data">
                        <h2>90<span>%</span></h2>
                        <p>全市占比</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- 入池企业与学校 -->
        <div class="sub-web-column enter-pool-column">
            <div class="content-wrap">
                <div class="column-title"><h1>入池企业与学校</h1></div>
                <div class="pool-company-logo flex">
                    <ul class="flex">
                        <li v-for="(item, key) in dataAdQiye" :key="key">
                            <img :src="item.imageUrl" :alt="item.qname">
                        </li>
                        <li v-for="(item, key) in dataAdSchool" :key="key">
                            <img :src="item.imageUrl" :alt="item.qname">
                        </li>
                    </ul>
                    <p class="pool-text">更快更优质地<br/>为企业智能制造升级提供人才支持</p>
                </div>
            </div>
        </div>
        <!-- 联系我们 -->
        <div class="sub-web-column" id="contact-xsc">
            <div class="content-wrap">
                <div class="column-title"><h1>联系我们</h1><h4>专属客服，一对一联系</h4></div>
                <contact-us/>
            </div>
        </div>

        <friend-link/>
        <bottom-foot/>
    </div>
</template>
<script>
import topHeader from '../../components/common/topHeader.vue';
import contactUs from '../../components/common/contactUS.vue';
import friendLink from '../../components/common/friendLink.vue';
import bottomFoot from '../../components/common/bottomFoot.vue';
import {Swiper, SwiperSlide} from 'swiper/vue';
import {Navigation,} from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';

export default {
    components: {
        topHeader,
        contactUs,
        friendLink,
        bottomFoot,
        Swiper,
        SwiperSlide,
    },
    setup() {
        const onSwiper = (swiper) => {

        };
        const onSlideChange = () => {

        };
        return {
            onSwiper,
            onSlideChange,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            modules: [Navigation,],
        };
    },
    data() {
        return {
            scenario: 'serviceXsc',
            postData: {},
            dataAdQiye: [],
            dataAdSchool: [],
            dataNewsJinggong: [],
            dataNewsShare: [],
        };
    },
    created() {
        this.getData();
    },
    methods: {
        getData() {
            this.http('/www/home/xsc', this.postData, (res) => {
                this.dataAdQiye = res.list_ad_qiye.data;
                this.dataAdSchool = res.list_ad_school.data;
                this.dataNewsJinggong = res.list_news_jinggong.data;
                this.dataNewsShare = res.list_news_share.data;
            }, null, 2);
        }
    }
}
</script>
<style lang="less" src="../../assets/css/init/common.less" scoped></style>
<style lang="less" src="../../assets/css/service/service.less" scoped>
</style>
<style lang="less" src="../../assets/css/service/xusc.less" scoped>
</style>