<template>
    <div class="wrap">
        <top-header
            :scenario="'service'"
        />

        <div class="web-banner">
            <img src="../../assets/img/hr_banner.png" alt="">
            <div class="banner-word flex align-items">
                <h3>人力资源服务</h3>
                <h4>⼗佳⼈⼒资源供给服务单位</h4>
                <a-anchor wrapperClass="contact-anchor" :affix="false"><a-anchor-link href="#contact" title="联系我们" /></a-anchor>
            </div>
        </div>
        <div class="sub-web-column">
            <div class="content-wrap service-intro-text">
                <h2>我们的服务</h2>
                <p>爱沃客人力资源外包服务，以降低人力成本，实现效率最大化。总体而言，爱沃客服务将渗及到企业内部的所有人事业务，包括人力资源规划、制度设计与创新、流程整合、员工满意度调查、薪资调查及方案设计、培训工作、劳动仲裁、员工关系、企业文化设计等方方面面。
</p>
                <p>爱沃客劳务输出对用人单位来说，让用工单位的人力资源部门从传统的“招人、管人、办理劳动事务”用工方式中解脱，集中精力从事人力资源核心工作，降低管理成本，并让用人单位避免劳资纠纷，转移劳资风险集中精力参加市场竞争，提高用工单位核心竞争力。</p>
                <p>爱沃客团队有丰富运营经验，为合肥经济技术开发区大中型企业提供稳定人力资源外包及劳务派遣服务，赢得广泛得好评。</p>
            </div>
        </div>
        <!-- 核心业务 -->
        <div class="sub-web-column service-business">
            <div class="content-wrap">
                <div class="column-title"><h1>核心业务</h1></div>
                <div class="core-business-column flex">
                    <div class="business-list">
                        <img src="../../assets/img/hr_coreB_01.png" alt="">
                        <div class="business-title">
                            <h3>劳务派遣</h3>
                            <div class="b-sub-title">
                                <h4>完全派遣</h4>
                                <p>爱沃客负责⼈才招募、选拔、培训、绩效评价、报酬和福利、劳务纠纷处理等。</p>
                            </div>
                            <div class="b-sub-title">
                                <h4>转移派遣</h4>
                                <p>企业⾃⾏招募或者已聘⽤的员⼯，转由爱沃客与员⼯签订劳动合同，增强企业⾯对⻛险时的组织应变能⼒。</p>
                            </div>
                        </div>
                    </div>
                    <div class="business-list">
                        <img src="../../assets/img/hr_coreB_02.png" alt="">
                        <div class="business-title">
                            <h3>业务外包</h3>
                            <div class="b-sub-title">
                                <h4>制造业外包、服务业外包</h4>
                                <p>企业把内部业务的一部分外包给爱沃客，使企业脱离低附加值工段的管理事务，专注于核心业务。</p>
                                <p>优化企业管理流程，人员规模增减灵活，快速响应业务变化，规避企业一般用工风险、业务波动风险、派遣制连带责任风险等。</p>
                            </div>
                        </div>
                    </div>
                    <div class="business-list">
                        <img src="../../assets/img/hr_coreB_03.png" alt="">
                        <div class="business-title">
                            <h3>灵活用工</h3>
                            <div class="b-sub-title">
                                <p>爱沃客为企业提供一站式灵活用工服务，满足企业波峰波谷需求，支持客户提升组织灵活性、降低雇佣风险和成本。</p>
                            </div>
                        </div>
                    </div>
                    <div class="business-list">
                        <img src="../../assets/img/hr_coreB_04.png" alt="">
                        <div class="business-title">
                            <h3>人力资源外包（HRO）</h3>
                            <div class="b-sub-title">
                                <p>企业将人力资源管理职能的外部化，主要包括人事代理、薪酬、福利外包、RPO招聘流程外包。</p>
                            </div>
                        </div>
                    </div>
                    <div class="business-list business-list02 flex align-items">
                        <img src="../../assets/img/hr_coreB_05.png" alt="">
                        <div class="business-title">
                            <h3>共享用工</h3>
                            <div class="b-sub-title">
                                <p>爱沃客将不同用工主体之间闲置劳动力资源进行跨界共享，并调配至具有用工需求缺口的用工主体，实现社会人力资源优化配置，员工供给方降低人力成本，员工需求方解决“用工荒”，待岗员工获得劳动报酬的多方共赢式新型合作用工模式。</p>
                                <p>2020-2021年疫情期间在合肥经开区实现共享用工697人次。</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 核心产品 -->
        <div class="sub-web-column">
            <div class="content-wrap">
                <div class="column-title"><h1>核心产品</h1></div>
                <div class="product-column">
                    <div class="product-title flex align-items">
                        <div class="product-logo"><img src="../../assets/img/feichai_logo.svg" alt="" width="83px" height="38px"></div>
                        <div class="product-link">
                            <h3>找工作，上肥差</h3>
                            <p>更多信息，请访问<a href="https://www.feichaijob.com/" target="_blank">https://www.feichaijob.com/</a></p>
                        </div>
                    </div>
                    <div class="product-display-fc">
                        <div class="display-pro-text">
                            <p>以蓝领找工作为核心，打造职位真实有效、报名简洁方便、沟通专业高效、跟进有序负责的求职平台。肥差，依托深厚的人力资源服务经验，解决蓝领人才关心的痛点，建立全流程服务体系，从展示细节到沟通入职提供一站式入职体验。</p>
                        </div>
                        <div class="pro-fc-feature flex">
                            <div class="feature">
                                <span class="f-icon1"></span>
                                <p>真实有效、大厂直招</p>
                            </div>
                            <div class="feature">
                                <span class="f-icon2"></span>
                                <p>蓝领工作、一键报名</p>
                            </div>
                            <div class="feature">
                                <span class="f-icon3"></span>
                                <p>专业顾问、高效对接</p>
                            </div>
                            <div class="feature">
                                <span class="f-icon4"></span>
                                <p>工资奖金、在线查询</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="product-column">
                    <div class="product-title flex align-items">
                        <div class="product-logo"><img src="../../assets/img/HROlogo.svg" alt="" width="250px" height="44px"></div>
                        <div class="product-link">
                            <h3>全流程人力资源外包管理云平台</h3>
                            <p>更多信息，请访问<a href="http://www.hellohro.com/" target="_blank">http://www.hellohro.com/</a></p>
                        </div>
                    </div>
                    <div class="product-display-fc">
                        <div class="display-pro-text">
                            <p>HelloHRO致力于为人力资源企业提供一站式SAAS平台。</p>
                            <p>为行业内公司在人才输送过程中面临的人才招聘、派遣员工登记、员工管理、工资发放、财务管理等问题而量身打造的一款云平台解决方案，助其提高效率、降低成本、增加服务黏性，优化流程，满足企业信息化协同办公的需求。</p>
                        </div>
                        <div class="pro-hro-feature flex">
                            <div class="hro-feature">
                                <div class="hro-f-icons flex">
                                    <span class="f-icon01"></span>
                                    <div class="right-text">
                                        <h3>提升工作效率  减少重复操作</h3>
                                        <p>你还在不断地用Excel表格管理数据吗？数据的正确率可以保证吗？</p>
                                    </div>
                                </div>
                                <div class="hro-f-icons flex">
                                    <span class="f-icon02"></span>
                                    <div class="right-text">
                                        <h3>各环节无缝对接 实现管理价值</h3>
                                        <p>H5与PC端将组织、HR、员工无缝对接，为HR提供一站式解决方案</p>
                                    </div>
                                </div>
                                <div class="hro-f-icons flex">
                                    <span class="f-icon03"></span>
                                    <div class="right-text">
                                        <h3>规避财务风险 优化收入结构</h3>
                                        <p>财务实时掌握回款进度，财务管理一目了然，随心查询出入账信息</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 合作企业 -->
        <div class="sub-web-column hr-hz-company-bg">
            <div class="content-wrap">
                <div class="column-title"><h1>合作企业</h1></div>
                <div class="hz-company-logo flex">
                    <template v-for="(item, key) in data" :key="key">
                        <img :src="item.imageUrl" :alt="item.qname">
                    </template>
                </div>
            </div>
        </div>
        <!-- 联系我们 -->
        <div class="sub-web-column" id="contact">
            <div class="content-wrap">
                <div class="column-title"><h1>联系我们</h1><h4>专属客服，一对一联系</h4></div>   
                <contact-us/> 
            </div>
        </div>

        <friend-link/>
        <bottom-foot/>
    </div>
</template>
<script>
import topHeader from '../../components/common/topHeader.vue';
import contactUs from '../../components/common/contactUS.vue';
import friendLink from '../../components/common/friendLink.vue';
import bottomFoot from '../../components/common/bottomFoot.vue';
export default {
    components: {
        topHeader,
        contactUs,
        friendLink,
        bottomFoot,
    },
    data () {
        return {
            scenario: 'serviceHR',
            postData: {
                flag: 'hr_qiye'
            },
            data: [],
        };
    },
    created() {
        this.getData();
    },
    methods: {
        getData() {
            this.http('/www/home/qiyead', this.postData, (res) => {
                this.data = res.list.data;
            }, null, 2);
        }
    }
}
</script>
<style lang="less" src="../../assets/css/init/common.less" scoped></style>
<style lang="less" src="../../assets/css/service/service.less" scoped>

</style>