<template>
    <div class="wrap">
        <top-header
            :scenario="'about'"
        />

        <div class="web-banner">
            <img src="../../assets/img/about_us_banner.png" alt="">
            <div class="banner-word flex align-items">
                <h3>爱沃客</h3>
                <h4>IWOWKE</h4>
            </div>
        </div>

        <web-tabs
            :select-key="4"
        />

        <div class="about-column">
            <div class="content-wrap">
                <div class="public-welfare-idea flex align-items">
                    <div class="img"><img src="../../assets/img/wogongyi.png" alt=""></div>
                    <div class="text">
                        <h3>愿景</h3>
                        <p>弘扬企业社会责任，激发团队智慧⾏动，创建公平美好的社会。</p>
                        <h3>资助理念</h3>
                        <p>沃公益重点资助“社会企业弱势员⼯”及“贫困⽣”两⼤领域。</p>
                    </div>
                </div>
                <div class="public-welfare-news">
                    <div class="news-column flex">
                        <div class="news" v-for="(item, key) in data" :key="key">
                            <a :href="'/news/' + item.newsid" target="_blank">
                                <img :src="item.imageUrl" :alt="item.title">
                                <h3 class="word-hidden">{{ item.title }}</h3>
                                <p class="row3">{{ item.description }}</p>
                            </a>
                        </div>
                    </div>
                    <web-pagination/>
                </div>
            </div>
        </div>

        <friend-link/>
        <bottom-foot/>
    </div>
</template>
<script>
import topHeader from '../../components/common/topHeader.vue';
import webTabs from '../../components/common/webTabs.vue';
import friendLink from '../../components/common/friendLink.vue';
import bottomFoot from '../../components/common/bottomFoot.vue';
import webPagination from '../../components/common/webPagination.vue';

export default {
    components: {
        topHeader,
        webTabs,
        friendLink,
        bottomFoot,
        webPagination,
    },
    data () {
        return {
            scenario: 'aboutGongyi',
            postData: {
                flag: 'gongyi',
                page: 1,
                pagesize: 6,
            },
            data: [],
            count: '0',
        };
    },
    created() {
        this.getData();
    },
    methods: {
        getData() {
            this.http('/www/home/news-list', this.postData, (res) => {
                this.data = res.list.data;
                this.count = res.list.count;
            }, null, 2);
        }
    }
}
</script>
<style lang="less" src="../../assets/css/init/common.less" scoped>

</style>
<style lang="less" src="../../assets/css/about/companyProfile.less" scoped>

</style>