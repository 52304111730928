import {createRouter, createWebHistory} from 'vue-router';

import home from './home';
import service from './service';
import about from './about';
import contact from './contact';
import news from './news';


const routes = [
    ...home,
    ...service,
    ...about,
    ...contact,
    ...news,
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});


router.beforeEach((to, from) => {

    // 保持缓存页点击位置
    if (from.meta.keepAlive) {
        const $content = document.querySelector('#content');
        const scrollTop = $content ? $content.scrollTop : 0;
        from.meta.scrollTop = scrollTop;
    }

    //console.log(to);
    // console.log(from);

    // 设置标题
    document.title = to.meta.title;

    if (to.meta.content) {
        let head = document.getElementsByTagName('head');
        let meta = document.createElement('meta');
        document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
        document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
        meta.content = to.meta.content;
        head[0].appendChild(meta)
    }

});

router.afterEach((to, from) => {
    // 新打开页面，跳转至顶部
    window.scrollTo(0, 0);
});

export default router
