<template>
    <div class="wrap">
        <top-header
            :scenario="'news'"
            class="white-bg"
        />

        <div class="news-detail-column news-min-height">
            <div class="content-wrap">
                <h1>{{ data.title }}</h1>
                <div class="see-time flex">
                    <span>{{ data.addtime_str}}</span>
                    <span>{{ data.viewnum }} 次浏览</span>
                </div>

                <div class="news-content" v-html="data.content">
                </div>
            </div>
        </div>
        
        <bottom-foot/>
    </div>
</template>
<script>
import topHeader from '../../components/common/topHeader.vue';
import bottomFoot from '../../components/common/bottomFoot.vue';
export default {
    components: {
        topHeader,
        bottomFoot,
    },
    data () {
        return {
            scenario: 'newsDetail',
            postData: {
                newsid: 0
            },
            data: [],
        };
    },
    created() {
        this.setQuery();

        this.getData();
    },
    methods: {
        getData() {
            this.http('/www/home/news-detail', this.postData, (res) => {
                this.data = res.data;

                document.title = this.data.title;

                // 增加阅读量
                this.http('/www/home/news-view', this.postData, (res) => {
                }, null, 2);

            }, null, 2);
        }
    }
}
</script>
<style lang="less" src="../../assets/css/init/common.less" scoped>

</style>
<style lang="less" src="../../assets/css/news/news.less">

</style>