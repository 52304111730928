<template>
    <div class="web-tabs">
        <div class="">
            <a-tabs v-model:activeKey="activeKey" tabPosition="left" @tabClick="changeTab">
                <a-tab-pane key="" tab="全部动态"></a-tab-pane>
                <a-tab-pane v-for="(item, key) in config.class" :key="item.flag" :tab="item.ncname"></a-tab-pane>
            </a-tabs>
        </div>
    </div>
</template>
<script>
import {defineComponent} from 'vue';

export default defineComponent({
    props: {
        selectKey: {
            type: String,
            default: '',
        },
        config: {
            type: Object,
            default: {},
        },
    },
    data () {
        return {
            activeKey: this.selectKey.toString(),
        }
    },
    setup() {

    },
    methods: {
        changeTab(val) {
            if (val == this.selectKey) return;

            location.href='/news?flag=' + val;
        }
    },
});
</script>
<style lang="less" scoped>
@import url(../../assets/css/init/var.less);

/deep/ .ant-tabs {
    color: @font-color666;
}

/deep/ .ant-tabs-tab {
    font-size: @init-font;
    padding: 25px 0;
}

/deep/ .ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: 600;
}

/deep/ .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
    height: 4px;
}

/deep/ .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 45px;
}

/deep/ .ant-tabs-nav-list {
    width: 1200px;
    margin: 0 auto;
}
</style>