import about from '../views/about/intro.vue';
import aboutHonor from '../views/about/honor.vue';
import aboutWenhua from '../views/about/wenhua.vue';
import aboutWelfare from '../views/about/welfare.vue';
import aboutCompany from '../views/about/company.vue';

export default [
    {
        path: '/about',
        name: '',
        meta: {
            title: '公司介绍 - 爱沃客',
            content: {
                keywords: "爱沃客公司简介,肥差,职业教育,继续教育,人力资源蓄水池,人才蓄水池,共享用工,校企共建,二级学院,沃公益",
                description: "安徽爱沃客信息科技有限公司是以⼈⼒资源服务、职业教育服务、继续教育服务为主的科技型⼈⼒资源及⼈才发展服务公司。",
            },
            requireLogin: false,
            keepAlive: false,
        },
        component: about,
    },
    {
        path: '/about/honor',
        name: '',
        meta: {
            title: '资质荣誉 - 爱沃客',
            content: {
                keywords: "爱沃客资质荣誉,肥差,职业教育,继续教育,人力资源蓄水池,人才蓄水池,共享用工,校企共建,二级学院,沃公益",
                description: "安徽爱沃客信息科技有限公司是以⼈⼒资源服务、职业教育服务、继续教育服务为主的科技型⼈⼒资源及⼈才发展服务公司。",
            },
            requireLogin: false,
            keepAlive: false,
        },
        component: aboutHonor,
    },
    {
        path: '/about/wenhua',
        name: '',
        meta: {
            title: '企业文化 - 爱沃客',
            content: {
                keywords: "爱沃客企业文化,肥差,职业教育,继续教育,人力资源蓄水池,人才蓄水池,共享用工,校企共建,二级学院,沃公益",
                description: "安徽爱沃客信息科技有限公司是以⼈⼒资源服务、职业教育服务、继续教育服务为主的科技型⼈⼒资源及⼈才发展服务公司。",
            },
            requireLogin: false,
            keepAlive: false,
        },
        component: aboutWenhua,
    },
    {
        path: '/about/gongyi',
        name: '',
        meta: {
            title: '沃公益 - 爱沃客',
            content: {
                keywords: "沃公益,爱沃客,肥差,职业教育,继续教育,人力资源蓄水池,人才蓄水池,共享用工,校企共建,二级学院,沃公益",
                description: "安徽爱沃客信息科技有限公司是以⼈⼒资源服务、职业教育服务、继续教育服务为主的科技型⼈⼒资源及⼈才发展服务公司。",
            },
            requireLogin: false,
            keepAlive: false,
        },
        component: aboutWelfare,
    },
    {
        path: '/about/company',
        name: '',
        meta: {
            title: '合作企业 - 爱沃客',
            content: {
                keywords: "爱沃客合作企业,肥差,职业教育,继续教育,人力资源蓄水池,人才蓄水池,共享用工,校企共建,二级学院,沃公益",
                description: "安徽爱沃客信息科技有限公司是以⼈⼒资源服务、职业教育服务、继续教育服务为主的科技型⼈⼒资源及⼈才发展服务公司。",
            },
            requireLogin: false,
            keepAlive: false,
        },
        component: aboutCompany,
    },
];