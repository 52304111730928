<template>
    <div class="web-tabs">
        <div class="">
            <a-tabs v-model:activeKey="activeKey" @tabClick="changeTab">
                <a-tab-pane key="1" tab="企业介绍"></a-tab-pane>
                <a-tab-pane key="2" tab="资质荣誉" force-render></a-tab-pane>
                <a-tab-pane key="3" tab="企业文化"></a-tab-pane>
                <a-tab-pane key="4" tab="沃公益"></a-tab-pane>
                <a-tab-pane key="5" tab="合作企业"></a-tab-pane>
            </a-tabs>
        </div>
    </div>
</template>
<script>
import {defineComponent} from 'vue';

export default defineComponent({
    props: {
        selectKey: {
            type: Number,
            default: 1,
        },
    },
    data () {
        return {
            activeKey: this.selectKey.toString(),
        }
    },
    setup() {

    },
    methods: {
        changeTab(val) {
            if (val == this.selectKey) return;

            switch (val) {
                case '2':
                    this.locationTo('/about/honor');
                    break;
                case '3':
                    this.locationTo('/about/wenhua');
                    break;
                case '4':
                    this.locationTo('/about/gongyi');
                    break;
                case '5':
                    this.locationTo('/about/company');
                    break;
                default:
                    this.locationTo('/about');
                    break;
            }
        }
    },
});
</script>
<style lang="less" scoped>
@import url(../../assets/css/init/var.less);

/deep/ .ant-tabs {
    color: @font-color666;
}

/deep/ .ant-tabs-tab {
    font-size: @init-font;
    padding: 25px 0;
}

/deep/ .ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: 600;
}

/deep/ .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
    height: 4px;
}

/deep/ .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 45px;
}

/deep/ .ant-tabs-nav-list {
    width: 1200px;
    margin: 0 auto;
}
</style>