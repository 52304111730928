<template>
    <router-view v-slot="{ Component }">
        <keep-alive>
            <component :is="Component" :key="$route.name" v-if="$route.meta.keepAlive" />
        </keep-alive>
        <component :is="Component" :key="$route.name" v-if="!$route.meta.keepAlive" />
    </router-view>
</template>

<style lang="less">
@import url(./assets/css/init/init.less);
@import url(//at.alicdn.com/t/c/font_3730270_2yjrvi3c3ps.css);
</style>
