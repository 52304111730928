<template>
    <div class="home">
        <top-header
            :scenario="scenario"
            :class="[active, colorLogo]"
            class="transparent-header"
        />

        <swiper
            class="mySwiper swiper-h"
            :modules="modules"
            :direction="'vertical'"
            :pagination="true"
            :mousewheel="true"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
            ref="swiper"
        >
            <swiper-slide>
                <div class="home-first-column">
                    <div class="black-bg"></div>
                    <h1><span>爱沃客</span>·<span>让工作更简单</span></h1>
                    <h3>makes work easier makes work easier</h3>
                    <p class="line"></p>

                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="home-second-column">
                    <div class="column-info column-info-common flex">
                        <h1>人力资源服务</h1>
                        <p>凭借专业化的服务团队以及多年的人力资源行业服务经验优势，以资源和价值共享为核心，以专业、科技、互联不断创新服务模式，为企业提供多元化的人力资源解决方案。</p>
                        <div class="hr-icons flex">
                            <div class="hr-icon">
                                <span class="icon icon01"></span>
                                <p>劳务派遣</p>
                            </div>
                            <div class="hr-icon">
                                <span class="icon icon02"></span>
                                <p>业务外包</p>
                            </div>
                            <div class="hr-icon">
                                <span class="icon icon03"></span>
                                <p>共享用工</p>
                            </div>
                            <div class="hr-icon">
                                <span class="icon icon04"></span>
                                <p>灵活用工</p>
                            </div>
                            <div class="hr-icon">
                                <span class="icon icon05"></span>
                                <p>人力资源外包</p>
                            </div>
                        </div>
                    </div>
                    <div class="left-circle flex">
                        <p></p>
                    </div>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="home-three-column flex align-items">
                    <div class="left-banner-column">
                        <div class="banner">
                            <swiper
                                class="mySwiper swiper-h"
                                :navigation="true"
                                :modules="modules"
                            >
                                <swiper-slide v-for="(item, key) in dataNews" :key="key">
                                    <img :src="item.imageSource" :alt="item.title">
                                </swiper-slide>
                                <div class="sjx"></div>
                            </swiper>
                        </div>
                        <div class="banner-text">
                            <h3>精工学院</h3>
                            <p>打造从招生、培养及就业一体化人才培养体系，培养出企业“免检”人才。计划至2025年，处理精工学院10所，预计五年内在校生将达10000人。</p>
                        </div>
                    </div>
                    <div class="column-info-common job-info flex">
                        <h1>职业教育服务</h1>
                        <p>以协同育人推进产教深度融合，逐步形成从招生、培养、就业一体化职业教育人才培养体系，
                            促进职业教育和产业人才需求精准对接，构建产教融合新生态，打造特色职业教育品牌。</p>
                        <div class="job-icons flex">
                            <h3>专业共建</h3>
                            <h3>校企共建</h3>
                            <h3>校外实训基地建设</h3>
                        </div>

                    </div>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="home-four-column flex">
                    <div class="column-info-common education-info flex">
                        <h1>继续教育服务</h1>
                        <h3>爱沃客网校</h3>
                        <p>集学历提升培训、职业资格考证培训于一体的综合培训与学院管理平台，内含丰富的名师课程及历年真题。</p>
                        <div class="phone-bg"><img src="../../assets/img/home_edu_phone.png" alt=""></div>
                    </div>
                    <div class="education-images flex">
                        <div class="left-images flex">
                            <div class="up-img img-title">
                                <img src="../../assets/img/home_edu_img02.png" alt="">
                                <div class="word"><h3>技能培训</h3></div>
                            </div>
                            <div class="down-img img-title">
                                <img src="../../assets/img/home_edu_img01.png" alt="">
                                <div class="word"><h3>管理培训</h3></div>
                            </div>
                        </div>
                        <div class="right-images img-title">
                            <img src="../../assets/img/home_edu_img03.png" alt="">
                            <div class="word">
                                <h3>学历提升培训</h3>
                                <p>为企业在职员工或社会人员<br/>提供学历提升考前培训及课中服务</p>
                            </div>

                        </div>
                    </div>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="home-second-column home-five-column">
                    <div class="right-bg">
                        <img src="../../assets/img/home_xsc_right_bg.png" alt="">
                        <img src="../../assets/img/home_xsc_bg_zz.png" alt="" class="zhez">
                    </div>
                    <div class="column-info column-info-common flex">
                        <h1>人力资源蓄水池</h1>
                        <p>安徽省首个政府主导的人力资源服务创新项目。<br/>
                            以政府牵头搭建“政、企、校”三方合作，利用精细化合作模式与学校建立长期有效的人才储备机制，以定向人才培养实施人力资源“外引水”，以“引企入教”促进教育链、人才链与产业链、
                            创新链有机衔接，以专业共建和产业学院共建模式，通过定岗、双向、精准培养精益制造的工匠型人才，以精工带动普工形成人力资源 “蓄水池”，为企业提供人力资源支撑保障。</p>
                        <div class="hr-icons flex">
                            <div class="hr-icon">
                                <span class="icon icon06"></span>
                                <p>精工班</p>
                            </div>
                            <div class="hr-icon">
                                <span class="icon icon07"></span>
                                <p>共享用工</p>
                            </div>

                        </div>
                    </div>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="home-six-column">
                    <div class="column-info column-info-common flex">
                        <h1>合作企业</h1>
                        <div class="hz-company-logo flex">
                            <div class="logo flex" v-for="(item, key) in dataAd" :key="key">
                                <img :src="item.imageUrl" :alt="item.qname">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="home-friendlink">
                    <friend-link/>
                </div>
            </swiper-slide>
        </swiper>
        <!-- 鼠标图标 -->
        <div class="mouse-icon" :class="none"><i class="iconfont icon-mouse"></i></div>
        <home-foot :class="whiteFoot"/>
    </div>
</template>

<script> 
import {ref} from 'vue';
import {Swiper, SwiperSlide} from 'swiper/vue';
import {Pagination, Mousewheel,Navigation} from 'swiper';
import 'swiper/css/bundle';
import "swiper/css/navigation";

import topHeader from '../../components/common/topHeader.vue';
import homeFoot from '../../components/home/homeFoot.vue';
import friendLink from '../../components/common/friendLink.vue';
import { computed } from '@vue/runtime-core';

export default {
    data () {
        return {
            scenario: 'home',
            postData: {
            },
            dataAd: [],
            dataNews: [],
        };
    },
    components: {
        Swiper,
        SwiperSlide,
        topHeader,
        homeFoot,
        friendLink,
    },
    setup() {
        const onSwiper = (swiper) => {
            
        };

        let activeIndexx = ref(0);
        const onSlideChange = (e) => {
            activeIndexx.value = e.activeIndex
        };
        const active = computed(() => {
            if([2,3,4].includes(activeIndexx.value)){
                return 'black-nav'
            }
            return ''
        });
        const whiteFoot = computed(() => {
            if(activeIndexx.value === 0){
                return 'white-foot'
            }
            return ''
        });
        const none = computed(() => {
            if(activeIndexx.value === 0){
                return 'show'
            }
            return ''
        });
        const colorLogo = computed(() => {
            if([0,1,2,3,4].includes(activeIndexx.value)){
                return 'color-logo'
            }
            return ''
        });
        
        return {
            activeIndexx,
            active,
            whiteFoot,
            colorLogo,
            none,
            onSwiper,
            onSlideChange,
            modules: [Pagination, Mousewheel,Navigation],
        };
    },
    
    created() {
        this.getData();
    },
    methods: {
        getData() {
            this.http('/www/home/home', this.postData, (res) => {
                this.dataAd = res.list_ad.data;
                this.dataNews = res.list_news.data;
            }, null, 2);
        }
    }
}
</script>
<style lang="less" src="../../assets/css/home/home.less" scoped>

</style>

<style>
.home_sad {
    filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
    -webkit-filter: grayscale(100%);
}
</style>