<template>
    <div class="friend-link">
        <div class="content-wrap">
            <ul class="flex">
                <li>友情链接：</li>
                <li><a href="http://hrss.ah.gov.cn/" target="_blank">安徽省人力资源和社会保障厅 </a> | </li>
                <li><a href="http://rsj.hefei.gov.cn/" target="_blank">合肥市人力资源和社会保障局 </a> | </li>
                <li><a href="http://hetda.hefei.gov.cn/" target="_blank">合肥经济技术开发区管理委员会 </a> | </li>
                <li><a href="http://www.hfhrsip.com/" target="_blank">中国合肥人力资源产业园 </a> | </li>
                <li><a href="http://www.xschr.com/" target="_blank">人力资源蓄水池 </a> | </li>
                <li><a href="https://www.feichaijob.com/" target="_blank">肥差 </a>  |</li>
                <li><a href="http://bbzjzx.com/" target="_blank">安徽蚌埠技师学院 </a></li>
            </ul>
        </div>
    </div>
</template>
<style lang="less" scoped>
@import url(../../assets/css/init/var.less);
.friend-link{
    padding-top: 88px;
    padding-bottom: 30px;
    li a{
        margin: 0 5px;
    }
    li,a{
        color: @font-color666;
        font-size: 14px;
    }
}
</style>