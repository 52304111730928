import contactUs from '../views/contact/us.vue';

export default [
    {
        path: '/contact',
        name: '',
        meta: {
            title: '联系我们 - 爱沃客',
            content: {
                keywords: "爱沃客联系我们,肥差,职业教育,继续教育,人力资源蓄水池,人才蓄水池,共享用工,校企共建,二级学院,沃公益",
                description: "安徽爱沃客信息科技有限公司是以⼈⼒资源服务、职业教育服务、继续教育服务为主的科技型⼈⼒资源及⼈才发展服务公司。",
            },
            requireLogin: false,
            keepAlive: false,
        },
        component: contactUs,
    },
    
];