import serviceHR from '../views/service/hr.vue';
import serviceVocation from '../views/service/vocation.vue';
import serviceEdu from '../views/service/edu.vue';
import serviceXsc from '../views/service/xsc.vue';

export default [
    {
        path: '/service/hr',
        name: '',
        meta: {
            title: '人力资源服务 - 爱沃客',
            content: {
                keywords: "爱沃客人力资源服务,肥差,职业教育,继续教育,人力资源蓄水池,人才蓄水池,共享用工,校企共建,二级学院,沃公益",
                description: "安徽爱沃客信息科技有限公司是以⼈⼒资源服务、职业教育服务、继续教育服务为主的科技型⼈⼒资源及⼈才发展服务公司。",
            },
        },
        component: serviceHR,
    },
    {
        path: '/service/zhiye',
        name: '',
        meta: {
            title: '职业教育服务 - 爱沃客',
            content: {
                keywords: "爱沃客职业教育服务,肥差,职业教育,继续教育,人力资源蓄水池,人才蓄水池,共享用工,校企共建,二级学院,沃公益",
                description: "安徽爱沃客信息科技有限公司是以⼈⼒资源服务、职业教育服务、继续教育服务为主的科技型⼈⼒资源及⼈才发展服务公司。",
            },
        },
        component: serviceVocation,
    },
    {
        path: '/service/edu',
        name: '',
        meta: {
            title: '继续教育服务 - 爱沃客',
            content: {
                keywords: "爱沃客继续教育服务,肥差,职业教育,继续教育,人力资源蓄水池,人才蓄水池,共享用工,校企共建,二级学院,沃公益",
                description: "安徽爱沃客信息科技有限公司是以⼈⼒资源服务、职业教育服务、继续教育服务为主的科技型⼈⼒资源及⼈才发展服务公司。",
            },
        },
        component: serviceEdu,
    },
    {
        path: '/service/xsc',
        name: '',
        meta: {
            title: '人力资源蓄水池 - 爱沃客',
            content: {
                keywords: "爱沃客人力资源蓄水池,肥差,职业教育,继续教育,人力资源蓄水池,人才蓄水池,共享用工,校企共建,二级学院,沃公益",
                description: "安徽爱沃客信息科技有限公司是以⼈⼒资源服务、职业教育服务、继续教育服务为主的科技型⼈⼒资源及⼈才发展服务公司。",
            },
        },
        component: serviceXsc,
    },
];