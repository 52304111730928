<template>
    <div class="top-header flex align-items" :class="active">
        <div class="logo">
            <a href="javascript:;" @click="locationTo('/')">
                
            </a>
        </div>
        <nav>
            <ul class="flex">
                <li><a href="/" :class="defaultClass['home']">首页</a></li>
                <li>
                    <a-dropdown overlayClassName="arrow-up">
                        <a :class="'ant-dropdown-link ' + defaultClass['service']" @click.prevent>
                            客户服务
                            <i class="iconfont icon-arrow1"></i>
                        </a>
                        <template #overlay>
                            <a-menu>
                                <a-menu-item>
                                    <a href="/service/hr">人力资源服务</a>
                                </a-menu-item>
                                <a-menu-item>
                                    <a href="/service/zhiye">职业教育服务</a>
                                </a-menu-item>
                                <a-menu-item>
                                    <a href="/service/edu">继续教育服务</a>
                                </a-menu-item>
                                <a-menu-item>
                                    <a href="/service/xsc">人力资源蓄水池</a>
                                </a-menu-item>
                            </a-menu>
                        </template>
                    </a-dropdown>
                </li>
                <li><a href="/news" :class="defaultClass['news']">新闻动态</a></li>
                <li><a href="/about" :class="defaultClass['about']">关于爱沃客</a></li>
                <li><a href="/contact" :class="defaultClass['contact']">联系我们</a></li>
            </ul>
        </nav>
    </div>
</template>
<script>

export default {
    components: {
    },
    props: {
        scenario: {
            type: String,
            default: '',
        },
    },
    watch: {
        // 实现双向绑定
        scenario(val) {
            console.log(val)

        },
    },
    data () {
        return {
            defaultClass: [],
            active: '',
        };
    },
    methods: {
      vueOnScroll() {
        //var prev = window.pageYOffset;
        window.addEventListener("scroll", () => {
          var curr = window.pageYOffset;
          var bannerHeight = document.querySelector(".web-banner");
          if (curr > bannerHeight) {
            this.active = 'white-bg';
          } else {
            this.active = 'white-bg';
          }
          if (curr <= bannerHeight || curr === 0) {
            this.active = '';
          }
           
        });
      },
    },
    mounted() {
      // run the function when the component's mount
      this.vueOnScroll();
    },
    created() {
        this.defaultClass[this.scenario] = 'active';
    }
};
</script>
<style lang="less" scoped>
@import url(../../assets/css/init/var.less);

.top-header {
    width: 100%;
    height: 80px;
    background-color: @main-color;
    justify-content: space-between;
    padding: 0 48px;
    box-sizing: border-box;
}

.logo {
    a{
        display: block;
        width: 106px;
        height: 36px;
        background: url(../../assets/img/logo_white.svg) no-repeat center;
    }
}
nav {
    li {
        padding: 0 33px;
        a {
            display: block;
            line-height: 80px;
            color: @white;
            position: relative;
            transition: all 0.1s ease-in;
            i.icon-arrow1{
                font-size: 12px;
                margin-left: 5px;
                display: inline-block;
            }
        }
        /deep/a.ant-dropdown-open{
            i.icon-arrow1{
                transform: rotate(180deg);
            }
        }
        a::after {
            content: '';
            display: block;
            width: 28px;
            height: 3px;
            position: absolute;
            bottom: 0px;
            left: 50%;
            right: 50%;
            transform: translate(-50%, 0);
            transition: all 0.2s ease-in;
        }

        a:hover::after {
            bottom: 9px;
            background-color: @white;
        }

        a:hover {
            font-weight: 600;
            transition: all 0.1s ease-out;
        }
        .active {
            font-weight: 600;
        }
        .active::after {
            content: '';
            display: block;
            width: 28px;
            height: 3px;
            position: absolute;
            bottom: 9px;
            left: 50%;
            right: 50%;
            background-color: @white;
        }
    }

}
/deep/ul.ant-dropdown-menu{
    border-radius: 8px;
}

/deep/.ant-dropdown-menu-item {
    padding: 6px 22px;
    color: @font-color4d;
}

/deep/.ant-dropdown-menu-title-content {
    a {
        font-size: 14px;
    }

    a:hover {
        color: @main-color;
    }
}
.white-bg{
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    
}
.transparent-header {
    background-color: transparent;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
}
.black-nav{
    nav{
        li{
            a{
                color: @font-color333;
            }
            .active, a:hover {
                color: @main-color;
            }

            .active::after, a:hover::after {
                background-color: @main-color;
            }
        }
    }
}
.color-logo{
    .logo {
        a{
            background: url(../../assets/img/logo_color.svg) no-repeat center;
        }
    }
}
</style>