<template>
    <div class="foot">
        <div class="content-wrap">
            <div class="foot-nav flex">
                <div class="left-copy">
                    <div class="copyright">版权所有 安徽爱沃客信息科技有限公司 &copy;CopyRight 2020-2022</div>
                    <div class="beian flex align-items"><a href="https://beian.miit.gov.cn/" target="_blank">皖ICP备18007608号-1</a><a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=34019102000300" target="_blank"><img src="../../assets/img/police.png"/>皖公网安备 34019102000300号</a></div>
                </div>
                <div class="right-code"><img src="../../assets/img/code.png" alt=""></div>
            </div>
        </div>
    </div>
</template>
<style lang="less" scoped>
@import url(../../assets/css/init/var.less);
.foot{
    width: 100%;
    background-color: @bg-colorf8;
    padding: 26px 0;
}
.foot-nav{
    justify-content: space-between;
    align-items: center;
}
.right-code{
    img{
        display: block;
        width: 100px;
        height: 100px;
    }
}
.left-copy{
    font-size: 12px;
    color: @font-color80;
}
.beian{
    padding-top: 12px;
    span{
        margin-right: 10px;
    }
    img{
        width: 18px;
        height: 18px;
        margin-right: 8px;
        margin-left: 8px;
    }
    a{
        font-size: 12px;
        color: @font-color80;
    }
}
</style>