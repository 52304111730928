<template>
    <div class="wrap">
        <top-header
            :scenario="scenario"
        />

        <div class="web-banner">
            <img src="../../assets/img/contact_us_banner.png" alt="">
            <div class="banner-word flex align-items">
                <h3>联系我们</h3>
                <h4>CONTACTUS</h4>
            </div>
        </div>
        <!-- 联系我们 -->
        <div class="contact-web-column">
            <div class="content-wrap">

                <contact-us/>

                <!-- map -->
                <div class="map">
                    <div id="container" class="our-map"></div>
                    <div class="company-position">
                        
                    </div>
                    <div class="position-text flex align-items">
                        <i class="iconfont icon-locale"></i>
                        <h3>公司地址</h3>
                        <p>安徽省合肥市经济技术开发区人力资源产业园3号楼</p>
                    </div>
                </div>
                
                
                

                <!-- contact form -->
                <div class="contact-form"  v-if="false">
                    <div class="column-title"><h1>联系我们</h1><h4>专属客服，一对一联系</h4></div>
                    <div class="form">
                        <a-form layout="inline">
                            <a-form-item>
                                <a-input placeholder="*联系人姓名"></a-input>
                            </a-form-item>
                            <a-form-item>
                                <a-input placeholder="*公司名称"></a-input>
                            </a-form-item>
                            <a-form-item>
                                <a-input placeholder="*联系电话"></a-input>
                            </a-form-item>
                            <a-form-item>
                                <a-input placeholder="邮箱"></a-input>
                            </a-form-item>
                            <a-form-item>
                                <a-input placeholder="业务说明"></a-input>
                            </a-form-item>
                            <a-form-item>
                                <a-input placeholder="图形验证码">
                                    <template #suffix>
                                        <img src="../../assets/img/wogongyi.png" alt="">
                                    </template>
                                </a-input>
                            </a-form-item>
                        </a-form>
                        <div class="contact-btn flex"><a-button size="large" type="primary">开始使用</a-button></div>
                    </div>
                </div>
            </div>
        </div>

        <friend-link/>
        <bottom-foot/>
    </div>
</template>
<script>
import topHeader from '../../components/common/topHeader.vue';
import contactUs from '../../components/common/contactUS.vue';
import friendLink from '../../components/common/friendLink.vue';
import bottomFoot from '../../components/common/bottomFoot.vue';
export default {
    components: {
        topHeader,
        contactUs,
        friendLink,
        bottomFoot,
    },
    data () {
        return {
            scenario: 'contact',
        };
    },
    mounted() {
        // 百度地图API功能
        var map = new BMap.Map("container");    // 创建Map实例
        map.centerAndZoom(new BMap.Point(117.237669,31.757385), 16);  // 初始化地图,设置中心点坐标和地图级别
        // 用经纬度设置地图中心点
        map.clearOverlays();
        var new_point = new BMap.Point(117.229521,31.757354);
        var marker = new BMap.Marker(new_point);  // 创建标注
        map.addOverlay(marker);              // 将标注添加到地图中
        // map.panTo(new_point);
    }
}
</script>

<style lang="less" src="../../assets/css/init/common.less" scoped></style>
<style lang="less" src="../../assets/css/service/service.less" scoped></style>
<style lang="less" src="../../assets/css/contact/contact.less" scoped></style>