<template>
    <div class="wrap">
        <top-header
            :scenario="'service'"
        />

        <div class="web-banner">
            <img src="../../assets/img/con_education_banner.png" alt="">
            <div class="banner-word flex align-items">
                <h3>继续教育服务</h3>
                <h4>专业的团队 · 优秀的产品 · 合作共赢</h4>
                <a-anchor wrapperClass="contact-anchor" :affix="false"><a-anchor-link href="#contact-edu" title="联系我们" /></a-anchor>
            </div>
        </div>
        <div class="sub-web-column grey-bg">
            <div class="content-wrap service-intro-text">
                <h2>学历提升培训</h2>
                <p class="text-center">为企业在职员⼯或社会⼈员提供学历提升考前培训及课中服务</p>
                <div class="academic-training-point flex">
                    <div class="point">
                        <img src="../../assets/img/con_training01.png" alt="">
                        <p>满18岁初高中、成人可报</p>
                    </div>
                    <div class="point">
                        <img src="../../assets/img/con_training02.png" alt="">
                        <p>学历入档、学信网可查</p>
                    </div>
                    <div class="point">
                        <img src="../../assets/img/con_training03.png" alt="">
                        <p>院校专业多、社会认可</p>
                    </div>
                    <div class="point">
                        <img src="../../assets/img/con_training04.png" alt="">
                        <p>在职提升、无需辞职</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- 技能提升培训 -->
        <div class="sub-web-column">
            <div class="content-wrap">
                <div class="column-title">
                    <h1>技能提升培训</h1>
                    <h4>技能等级证书考试辅导、岗位专业技能相关培训</h4>
                </div>
                <div class="skill-point flex">
                    <div class="point">
                        <img src="../../assets/img/con_skill01.png" alt="">
                        <p>提升职业技能、领取技能补贴</p>
                    </div>
                    <div class="point">
                        <img src="../../assets/img/con_skill02.png" alt="">
                        <p>官方认证、国网可查</p>
                    </div>
                    <div class="point">
                        <img src="../../assets/img/con_skill03.png" alt="">
                        <p>专业服务团队、更高的通过率</p>
                    </div>
                    <div class="point">
                        <img src="../../assets/img/con_skill04.png" alt="">
                        <p>各地市，均可考试</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- 管理培训 -->
        <div class="sub-web-column grey-bg">
            <div class="content-wrap">
                <div class="column-title">
                    <h1>管理培训</h1>
                    <h4>根据企业需求，为企业量身定制通用力、团队管理等职场综合能力培训</h4>
                </div>
                <div class="ppt-imgs flex">
                    <div class="ppt-img">
                        <img src="../../assets/img/con_ppt01.png" alt="">
                        <p>国内名师</p>
                    </div>
                    <div class="ppt-img">
                        <img src="../../assets/img/con_ppt02.png" alt="">
                        <p>行业大咖</p>
                    </div>
                    <div class="ppt-img">
                        <img src="../../assets/img/con_ppt03.png" alt="">
                        <p>多样化培训形式</p>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- 联系我们 -->
        <div class="sub-web-column" id="contact-edu">
            <div class="content-wrap">
                <div class="column-title"><h1>联系我们</h1><h4>专属客服，一对一联系</h4></div>   
                <contact-us/> 
            </div>
        </div>

        <friend-link/>
        <bottom-foot/>
    </div>
</template>
<script>
import topHeader from '../../components/common/topHeader.vue';
import contactUs from '../../components/common/contactUS.vue';
import friendLink from '../../components/common/friendLink.vue';
import bottomFoot from '../../components/common/bottomFoot.vue';
export default {
    components: {
        topHeader,
        contactUs,
        friendLink,
        bottomFoot,
    }
}
</script>
<style lang="less" src="../../assets/css/init/common.less" scoped></style>
<style lang="less" src="../../assets/css/service/service.less" scoped>
</style>
<style lang="less" src="../../assets/css/service/continu.less" scoped>
</style>