<template>
    <div class="wrap">
        <top-header
            :scenario="'service'"
        />

        <div class="web-banner">
            <img src="../../assets/img/vo_education_banner.png" alt="">
            <div class="banner-word flex align-items">
                <h3>职业教育服务</h3>
                <h4>外引“⽔”内调剂、探索⾼质量就业</h4>
                <a-anchor wrapperClass="contact-anchor" :affix="false"><a-anchor-link href="#contact-vocation" title="联系我们" /></a-anchor>
            </div>
        </div>
        <div class="sub-web-column">
            <div class="content-wrap service-intro-text">
                <h2>我们的服务</h2>
                <p class="text-center">公司以协同育⼈推进产教深度融合，逐步形成从招⽣、培养、就业⼀体化职业教育服务模式</p>
                <div class="edu-service-point flex">
                    <div class="point-list">
                        <span class="iconfont icon-service1"></span>
                        <h3>专业优化</h3>
                        <p>根据企业需求对现有专业培养模式进⾏优化，⾯向新⽣或在校⽣进⾏培养（从在校⽣中选拔或者双⽅共同招⽣），双⽅结合实际情况，分担教学的实施与管理等⼯作。</p>
                    </div>
                    <div class="point-list">
                        <span class="iconfont icon-service2"></span>
                        <h3>专业建设</h3>
                        <p>根据企业需求提供专业建设⽅案，学校负责申办新专业，双⽅共同招⽣，教学的实施与管理由双⽅协商确定并共同执⾏。</p>
                    </div>
                    <div class="point-list">
                        <span class="iconfont icon-service3"></span>
                        <h3>校企共建</h3>
                        <p>双⽅结合实际情况，发挥各⾃优势，选择部分专业合作，以学院的形式独⽴运作，双⽅共同招⽣，双⽅共同承担教学的实施与管理。</p>
                    </div>
                    <div class="point-list">
                        <span class="iconfont icon-service4"></span>
                        <h3>校外实训基地建设</h3>
                        <p>根据校内实践教学的延伸需求，在企业设⽴实训实习基地，通过在真实职业环境下的岗位实践，有效提⾼学⽣的技能⽔平，使学⽣完成关键能⼒的培养。同时对带队学校⽼师提供培训，推动“双师型”教师队伍的建设，由企业全⾯实施⽇常运营管理。</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- 核心业务 -->
        <div class="sub-web-column grey-bg">
            <div class="content-wrap">
                <div class="column-title"><h1>核心业务</h1></div>
                <div class="edu-business-column flex align-items">
                    <div class="left-text">
                        <h3>精工学院</h3>
                        <h4>致⼒于探索⼈才培养的新模式、新⽅向。</h4>
                        <p>以“立德树人”的教育理念对标职业标准，培养学生职业素养；通过定制化专业课程教学、企业游学、一线历练、角色实践学习路径，提升专业技能；将职业精神、工匠精神贯穿培养全过程，培养出企业“免检”人才。
。</p>
                        <p>以安徽为中心，计划至2025年，与全国职业院校合作成立爱沃客二级学院（精工学院）10所，预计在校生将达到10000人，打造特色职业教育品牌</p>
                    </div>
                    <div class="right-img"></div>
                </div>
                <div class="edu-business-column edu-business-column2 flex align-items">
                    <div class="left-text right-text">
                        <h3>校企共建</h3>
                        <p>企业深度参与学校专业规划、教材开发、教学设计、课程设置、实习实训，企业需求融⼊⼈才培养环节，推⾏⾯向企业真实⽣产环境的任务式培养模式。学校为学⽣创造更具竞争⼒的培养环境，让其学有专攻、学有所⽤，实现资源共享合作共赢。</p>
                        <p>通过校企合作为主线，整合各类学校资源，深耕校企共建，利⽤精细化合作模式与学校建⽴⻓期有效的⼈⼒资源储备机制，为企业做好技能型⼈才培养和储备。</p>
                    </div>
                    <div class="right-img right-img-xiaoqi"></div>
                </div>
            </div>
        </div>
        <!-- 目前成果 -->
        <div class="sub-web-column">
            <div class="content-wrap">
                <div class="column-title"><h1>目前成果</h1></div>
                <div class="fruit-display-column flex">
                    <div class="fruit-list" v-for="(item, key) in dataNews" :key="key">
                        <div class="fruit">
                            <a href="javascript:;" @click="actionTo('/news/' + item.newsid)">
                                <img :src="item.imageUrl" :alt="item.title">
                                <h3 class="word-hidden">{{ item.title }}</h3>
                                <p class="row3">{{ item.description }}</p>
                            </a>
                            <a href="javascript:;" @click="actionTo('/news')" class="more flex align-items">More<i class="iconfont icon-more"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 合作学校 -->
        <div class="sub-web-column grey-bg">
            <div class="content-wrap">
                <div class="column-title"><h1>合作学校</h1></div>
                <div class="hz-school-logo flex">
                    <div class="hz-school" v-for="(item, key) in dataAd" :key="key">
                        <img :src="item.imageUrl" :alt="item.qname">
                        <h3>{{  item.qname }}</h3>
                    </div>
                </div>
            </div>
        </div>
        <!-- 联系我们 -->
        <div class="sub-web-column" id="contact-vocation">
            <div class="content-wrap">
                <div class="column-title"><h1>联系我们</h1><h4>专属客服，一对一联系</h4></div>   
                <contact-us/> 
            </div>
        </div>

        <friend-link/>
        <bottom-foot/>
    </div>
</template>
<script>
import topHeader from '../../components/common/topHeader.vue';
import contactUs from '../../components/common/contactUS.vue';
import friendLink from '../../components/common/friendLink.vue';
import bottomFoot from '../../components/common/bottomFoot.vue';
export default {
    components: {
        topHeader,
        contactUs,
        friendLink,
        bottomFoot,
    },
    data () {
        return {
            scenario: 'serviceVocation',
            postData: {
                flag: 'vocation_school',
                pagesize: 14,
            },
            dataAd: [],
            dataNews: [],
        };
    },
    created() {
        this.getData();
    },
    methods: {
        getData() {
            this.http('/www/home/vocation', this.postData, (res) => {
                this.dataAd = res.list_ad.data;
                this.dataNews = res.list_news.data;
            }, null, 2);
        }
    }
}
</script>
<style lang="less" src="../../assets/css/init/common.less" scoped></style>
<style lang="less" src="../../assets/css/service/service.less" scoped>
</style>
<style lang="less" src="../../assets/css/service/voEducation.less" scoped>
</style>